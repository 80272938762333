import React from 'react';
import './Slider.scss';

const Slider = ({ rotate, setRotate }) => {
  return (
    <div className={rotate ? 'slider__container' : 'slider__container open'}>
      <a onClick={() => setRotate(false)} href='#home'>
        <h5>home</h5>
      </a>

      <a onClick={() => setRotate(false)} href='#aboutMe'>
        <h5>o mnie</h5>
      </a>
      <a onClick={() => setRotate(false)} href='#forYou'>
        <h5>dla ciebie</h5>
      </a>
      <a onClick={() => setRotate(false)} href='#forBusiness'>
        <h5>dla bizensu</h5>
      </a>
      <a onClick={() => setRotate(false)} href='#astro'>
        <h5>astrologia</h5>
      </a>
      <a onClick={() => setRotate(false)} href='#number'>
        <h5>numerologia</h5>
      </a>
      <a onClick={() => setRotate(false)} href='#meeting'>
        <h5>umów spotkanie</h5>
      </a>
    </div>
  );
};

export default Slider;
