import React from 'react';
import Confirmation from '../../Mobile/Meeting/Confirmation';
import './Appointment.scss';

const Appointment = ({ confirmation, setConfirmation }) => {
  return (
    <>
      {confirmation ? (
        <Confirmation setConfirmation={setConfirmation} klasa='browser' />
      ) : (
        <div id='appointment' className='appointment'>
          <div className='appointment__container'>
            <h1>Drodzy Państwo,</h1>
            <br />
            <p>
              Jeśli jesteście zainteresowani konsultacją, proszę o wysłanie
              podstawowych danych takich jak:
              <br />
              <ul>
                <h4>W przypadku konsultacji indywidualnych:</h4>
                <li>imię;</li>
                <li>pełna data urodzenia;</li>
                <li>
                  godzina urodzenia (jeśli nie jest znana, to przybliżona
                  godzina lub pora dnia);
                </li>
                <li>miejsce urodzenia.</li>
              </ul>
              <ul>
                <h4>W przypadku firmy:</h4>
                <li>nazwa firmy;</li>
                <li>
                  data utworzenia zgodnie z zapisem notarialnym lub innym
                  urzędowym;
                </li>
                <li>
                  dane wspólników jak w przypadku konsultacji indywidualnej
                  (wyszczególnione dane powyżej).
                </li>
              </ul>
              <br />
              <span>
                Powyższe dane proszę przesłać na adres mailowy:
                <a href='mailto: jokoastrology@gmail.com'>
                  jokoastrology@gmail.com
                </a>
                .
                <br />
                Przystąpienie do realizacji usług po otrzymaniu niezbędnych
                danych podstawowych.
                <br /> <br /> <br />
                <p className='podpis'>
                  Pozdrawiam serdecznie <br /> Joko Engele
                </p>
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Appointment;
