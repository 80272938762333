import React from 'react';
import '../MobileAboutMe/MobileAboutMe.scss';

const Mambo = ({ title, txt, id }) => {
  return (
    <>

    <div id={id} className='page__container'>
      <h1>{title}</h1>
      <p>{txt}</p>
    </div>
    </>
  );
};

export default Mambo;
