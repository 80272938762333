import React from 'react';
import './MumboJumbo.scss';

const MumboJumbo = () => {
  return (
    <div id='astro' className='mumbo__jumbo'>
      <div className='content__container'>
        <h1>Astrologia</h1>
        <div className='bull__shit'>
          <img
            className='lapy'
            src={process.env.PUBLIC_URL + '/images/lapska.png'}
            alt='lapy'
          />
          <div className='copy'>
            <p>
              To od dawna ugruntowana metoda odkrywania siebie, naszych relacji
              i naszego miejsca w świecie. Polega na przyjrzeniu się znaczącym
              wpływom środowiska, które w danym miejscu i czasie działają -
              wyrażone za pomocą horoskopu w symbolicznych relacjach ciał
              niebieskich.
            </p>
            <p>
             Astrologia, nauka o gwiazdach może nam dać wgląd we wszelkiego
              rodzaju sytuacje od osobistych po polityczne i od najbardziej
              intymnych po najbardziej przyziemne.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MumboJumbo;
