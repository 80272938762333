import React from 'react';
// import Blog from '../../Blog/Blog';
import AboutMe from '../AboutMe/AboutMe';
import Appointment from '../Appointment/Appointment';
import ForBusiness from '../ForBusiness/ForBusiness';
// import ForBusiness from '../ForBusiness/ForBusiness';
import ForYou from '../ForYou/ForYou';
import MumboJumbo from '../MumboJumbo/MumboJumbo';
import MumboJumboTwo from '../MumboJumbo/MumboJumboTwo';

import './Content.scss';

const Content = ({ confirmation, setConfirmation }) => {
  return (
    <>
      <div className='content__background'>
        <img
          className='zodiak'
          src={process.env.PUBLIC_URL + '/images/zodiak.png'}
          alt=''
        />
        <img
          className='background__sky'
          src={process.env.PUBLIC_URL + '/images/space_2.png'}
          alt=''
        />
      </div>

      <div className='content'>
        <AboutMe />
        <ForYou
          id='for__you'
          url='https://prod.spline.design/mqPU-RGlfLUQ2S2i/scene.splinecode'
          name='Dla Ciebie'
        />
        <ForBusiness id='for__business' name='Dla Biznesu' />
        <MumboJumbo />
        <MumboJumboTwo />
        <Appointment
          confirmation={confirmation}
          setConfirmation={setConfirmation}
        />
        {/* <Blog/> */}
      </div>
    </>
  );
};

export default Content;
