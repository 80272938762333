import React, { useState } from 'react';
import Burger from '../Burger/Burger';
import FirstScreen from '../FirstScreen/FirstScreen';
// import MobileMeeting from '../Meeting/MobileMeeting';
import MobileAboutMe from '../MobileAboutMe/MobileAboutMe';
import MobileForBusiness from '../MobileForBisunes/MobileForBusiness';
import MobileForYou from '../MobileForYou/MobileForYou';
import Mambo from '../Pierdy/Mambo';
import Slider from '../Slider/Slider';
import './MobileMenu.scss';
import MobileMeetingSimple from '../Meeting/MobileMeetingSimple';

const MobileMenu = ({ confirmation, setConfirmation }) => {
  const [rotate, setRotate] = useState(false);

  return (
    <>
      <Burger rotate={rotate} setRotate={setRotate} />
      <Slider rotate={rotate} setRotate={setRotate} />
      <div
        style={{
          width: '100vw',
          height: 'auto',
          position: 'absolute',
          top: 0,
          zIndex: 1,
        }}
        className=''
      >
        <FirstScreen />
        <MobileAboutMe />
        <MobileForYou />
        <MobileForBusiness />
        <Mambo
          id='astro'
          title='Astrologia'
          txt='To od dawna ugruntowana metoda odkrywania siebie, naszych relacji i naszego miejsca w świecie. Polega na przyjrzeniu się znaczącym wpływom środowiska, które w danym miejscu i czasie działają - wyrażone za pomocą horoskopu w symbolicznych relacjach ciał niebieskich.

Astrologia, nauka o gwiazdach może nam dać wgląd we wszelkiego rodzaju sytuacje od osobistych po polityczne i od najbardziej intymnych po najbardziej przyziemne.'
        />
        <Mambo
          id='number'
          title='Numerologia'
          txt='Pewnie czujesz, że czegoś brakuje w Twoim życiu... szczęścia, ktore możesz odkryć na nowo w sobie, a może szukasz wyjątkowej osoby, z którą możesz dzielić swoje życie...

Może jesteś po prostu ciekawy. Bez względu na to, co sprowadziło Cię do numerologii, będziesz zdumiony odpowiedziami, kierunkiem i jasnością, które może zapewnić numerologia.'
        />
        {/* <MobileMeeting
          confirmation={confirmation}
          setConfirmation={setConfirmation}
        /> */}
        <MobileMeetingSimple id='meeting' />
      </div>
    </>
  );
};

export default MobileMenu;
