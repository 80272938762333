import React from 'react';
import './MobileButton.scss';

const MobileButton = ({ title, href }) => {
  return (
    <div className='btn'>
      <a href={href}>{title}</a>
    </div>
  );
};

export default MobileButton;
