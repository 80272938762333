import React from 'react';
import './Menu.scss';

function Menu(props) {
  return (
    <div className='menu__container'>
      <ul>
        <a href='#home'>
          <li>
            <img src={process.env.PUBLIC_URL + `/images/home.svg`} alt='home' />
          </li>
        </a>

        <a href='#about_me'>
          <li>
            <p>O mnie</p>
          </li>
        </a>
        <a href='#for__you'>
          <li>
            <p>Dla Ciebie</p>
          </li>
        </a>
        <a href='#for__business'>
          <li>
            <p>Dla biznesu</p>
          </li>
        </a>
        <a href='#astro'>
          <li>
            <p>Astrologia</p>
          </li>
        </a>
        <a href='#numer'>
          <li>
            <p>Numerologia</p>
          </li>
        </a>
        <a href='#appointment'>
          <li>
            <p>Umów spotkanie</p>
          </li>
        </a>
        {/* <a href='#tarot'>
          <li>
            <p>Tarot</p>
          </li>
        </a>
                <a href='#blog'>
          <li>
            <p>Blog</p>
          </li>
        </a> */}
        {/* <a href='#astroprognoza'>
          <li>
            <p>Astroprognoza</p>
          </li>
        </a> */}
      </ul>
    </div>
  );
}

export default Menu;
