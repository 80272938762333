import React from 'react';
import '../MobileForYou/MobileForYou.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import Card from '../MobileForYou/Card/Card';

const MobileForBusiness = () => {
  return (
    <div id='forBusiness' className='page__container'>
      <h1>Dla biznesu</h1>
      <Swiper
        direction={'vertical'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className='mySwiper'
      >
        <SwiperSlide>
          <Card card='relacje' />
        </SwiperSlide>
        <SwiperSlide>
          <Card card='firmy' />
        </SwiperSlide>
        <SwiperSlide>
          <Card card='nazwa' />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MobileForBusiness;
