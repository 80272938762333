import React from 'react';
import './Banner.scss';

function Banner() {
  return (
    <>
      <div className='buttons'>
        <div className='btn oferta'>
          <a href='#for__you'>dla Ciebie</a>
        </div>
        <div className='btn oferta'>
          <a href='#for__business'>dla biznesu</a>
        </div>
        <div className='btn spotkanie'>
          <a href='#appointment'>Umów spotkanie</a>
        </div>
      </div>
      <div id='home' className='banner__container'>
        <img
          className='background__image'
          src={process.env.PUBLIC_URL + '/images/sky.png'}
          alt=''
        />
        <img
          className='moon'
          src={process.env.PUBLIC_URL + '/images/moon.png'}
          alt=''
        />

        <div className='clouds'>
          <img
            className='cloud'
            src={process.env.PUBLIC_URL + '/images/clouds/cloud1.png'}
            alt=''
          />
          <img
            className='cloud'
            src={process.env.PUBLIC_URL + '/images/clouds/cloud2.png'}
            alt=''
          />
          <img
            className='cloud'
            src={process.env.PUBLIC_URL + '/images/clouds/cloud3.png'}
            alt=''
          />
          <img
            className='cloud'
            src={process.env.PUBLIC_URL + '/images/clouds/cloud4.png'}
            alt=''
          />
          <img
            className='cloud'
            src={process.env.PUBLIC_URL + '/images/clouds/cloud5.png'}
            alt=''
          />
        </div>
        <div className='content__container'>
          <div className='logo__container'>
            <img
              className='logo'
              src={process.env.PUBLIC_URL + `/images/joko_logo.svg`}
              alt=''
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
