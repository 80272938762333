import React from 'react';
import './MobileAboutMe.scss';

const MobileAboutMe = () => {
  return (
    <div id='aboutMe' className='page__container'>
      <h1>O mnie</h1>
      <p>
        Nazywam się Joko Engele. Już od dziecka interesował mnie świat magii i
        jego wpływ na nasze ziemskie życie. Z pasją wertowałam książki o
        ezoteryce. Najbardziej interesowałam się astrologią i numerologią oraz
        czytaniem kart Tarota. Odbyłam wiele szkoleń w tym zakresie. Mam
        wieloletnie doświadczenie, co pozwala mi na niemal dokładne czytanie
        przeszłości oraz teraźniejszości i prognozowanie przyszłości danej
        osoby. Z naszej daty urodzin możemy wyczytać ogół informacji
        pozwalających nam przeżyć jak najlepiej nasze życie, odkryć nasz
        potencjał i dary, z którymi przyszliśmy na świat. Mój intuicyjny styl
        czytania z układu planet, który tworzy się w chwili naszych narodzin
        oraz liczb znajdujących się w naszej dacie urodzenia pozwala na rozwój i
        samopoznanie. Ja z chęcią pomagam zainteresowanym osobom odnaleźć tę
        drogę.
      </p>
      <img src={process.env.PUBLIC_URL + `/images/joko_podpis.svg`} alt='' />
    </div>
  );
};

export default MobileAboutMe;
