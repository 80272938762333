import React from 'react';
import './MobileMeeting.scss';

const Confirmation = ({ setConfirmation, klasa }) => {
  return (
    <div className='confirmation'>
      <div className={`confirmation__field ${klasa}`}>
        <img
          onClick={() => setConfirmation(false)}
          className='close'
          src={process.env.PUBLIC_URL + '/images/close.svg'}
          alt='close'
        />
        <p>Dziękuję za zainteresowanie i wysłanie zgłoszenia.</p>
        <p>Wkrótce skontaktuję się z Tobą w celu umówienia spotkania.</p>
        <img
          src={process.env.PUBLIC_URL + '/images/joko_podpis.svg'}
          alt='podpis'
        />
      </div>
    </div>
  );
};

export default Confirmation;
