import React from 'react';
import './MobileBackground.scss';

const MobileBackground = () => {
  return (
    <div className='mobileBackground__container'>
      <img
        src={process.env.PUBLIC_URL + `/images/space_2.png`}
        alt='space'
        className='pict__background'
      />
                  <img
      style={{opacity:1}}
        src={process.env.PUBLIC_URL + `/images/moon.png`}
        alt='moon'
        className='pict__background moon'
      />

            <img
      style={{opacity:1}}
        src={process.env.PUBLIC_URL + `/images/zodiak.svg`}
        alt='moon'
        className='pict__background zodiak'
      />
            <img
      style={{opacity:.5}}
        src={process.env.PUBLIC_URL + `/images/moon.png`}
        alt='moon'
        className='pict__background moon'
      />
    </div>
  );
};

export default MobileBackground;
