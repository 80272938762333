import React from 'react';
import './Burger.scss';

const Burger = ({ rotate, setRotate }) => {
  return (
    <>
      <img
        onClick={() => setRotate(!rotate)}
        className={rotate ? 'burger rotate' : 'burger'}
        src={process.env.PUBLIC_URL + `/images/hamburg.svg`}
        alt='burger'
      />
    </>
  );
};

export default Burger;
