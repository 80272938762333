import React from 'react';
import '../ForYou/ForYou.scss';

const ForBusiness = ({ id, name }) => {
  return (
    <div id={id} className='for__you'>
      <div className='content__container'>
        <h1>{name}</h1>
        <div className='cards__container business'>
          <img
            src={process.env.PUBLIC_URL + `/images/karty/relacje.svg`}
            alt=''
          />
          <img
            src={process.env.PUBLIC_URL + `/images/karty/firmy.svg`}
            alt=''
          />
          <img
            src={process.env.PUBLIC_URL + `/images/karty/nazwa.svg`}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};

export default ForBusiness;
