import React from 'react';
import MobileButton from '../MobileButton/MobileButton';
import './FirstScreen.scss';

const FirstScreen = () => {
  return (
    <div id='home' className='page__container'>
      <img src={process.env.PUBLIC_URL + '/images/joko_logo.svg'} alt='' />
      <MobileButton title='dla ciebie' href='#forYou' />
      <MobileButton title='dla biznesu' href='#forBusiness' />
      <MobileButton title='umów spotkanie' href='#meeting' />
    </div>
  );
};

export default FirstScreen;
