import React, { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import './App.scss';
import Banner from './components/Browser/Banner/Banner';
import Menu from './components/Browser/Menu/Menu';
import Content from './components/Browser/Content/Content';
import MobileBackground from './components/Mobile/MobileBackground/MobileBackground';
import MobileMenu from './components/Mobile/MobileMenu/MobileMenu';
import 'swiper/css/bundle';

function App(props) {
  const [confirmation, setConfirmation] = useState(false);
  const [values, setValues] = useState({
    name: '',
    email: '',
    date: '',
    hour: '',
    city: '',
  });

  return (
    <>
      <BrowserView>
        <Menu />
        <Banner />
        <Content
          values={values}
          setValues={setValues}
          confirmation={confirmation}
          setConfirmation={setConfirmation}
        />
      </BrowserView>
      <MobileView>
        <MobileBackground />
        <MobileMenu
          confirmation={confirmation}
          setConfirmation={setConfirmation}
        />
      </MobileView>
    </>
  );
}

export default App;
