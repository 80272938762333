import React from 'react';
import './MumboJumbo.scss';

const MumboJumboTwo = () => {
  return (
    <div id='numer' className='mumbo__jumbo'>
      <div className='content__container'>
        <h1>Numerologia</h1>
        <div className='bull__shit'>
          <img
            className='numery'
            src={process.env.PUBLIC_URL + '/images/numery.svg'}
            alt='lapy'
          />
          <div className='copy'>
            <p>
              Pewnie czujesz, że czegoś brakuje w Twoim życiu... szczęścia,
              ktore możesz odkryć na nowo w sobie, a może szukasz wyjątkowej osoby, z którą możesz dzielić swoje życie...
            </p>
            <p>
              Może jesteś po prostu ciekawy. Bez względu na to, co sprowadziło
              Cię do numerologii, będziesz zdumiony odpowiedziami, kierunkiem i jasnością, które może zapewnić numerologia.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MumboJumboTwo;
