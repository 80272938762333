import React from 'react';
import './MobileForYou.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import Card from './Card/Card';

const MobileForYou = () => {
  return (
    <div id='forYou' className='page__container'>
      <h1>Dla Ciebie</h1>
      <Swiper
        direction={'vertical'}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className='mySwiper'
      >
        <SwiperSlide>
          <Card card='urodz' />
        </SwiperSlide>
        <SwiperSlide>
          <Card card='rok_przod' />
        </SwiperSlide>
        <SwiperSlide>
          <Card card='kariera' />
        </SwiperSlide>
        <SwiperSlide>
          <Card card='uczucia' />
        </SwiperSlide>
        <SwiperSlide>
          <Card card='imie' />
        </SwiperSlide>
        <SwiperSlide>
          <Card card='pary' />
        </SwiperSlide>
        <SwiperSlide>
          <Card card='liczby' />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MobileForYou;
